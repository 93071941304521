import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";
import AuthService from "../services/AuthService";

type Token = {
  token: string | null;
  setStoredToken: (newToken: string) => void;
};

const TokenContext = createContext<Token | undefined>(undefined);

export const useToken = () => {
  const context = useContext(TokenContext);
  if (!context) {
    throw new Error("useToken must be used within a TokenProvider");
  }
  return context;
};

export const TokenProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    token && AuthService.setToken(token);
  }, [token]);

  useEffect(() => {
    setToken(AuthService.getStoredToken());
  }, []);

  const setStoredToken = (newToken: string) => {
    setToken(newToken);
  };

  const tokenContextValue: Token = {
    token,
    setStoredToken,
  };

  return (
    <TokenContext.Provider value={tokenContextValue}>
      {children}
    </TokenContext.Provider>
  );
};
