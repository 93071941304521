import { routes } from "../types/constants";

const { LOGIN, REGISTER } = routes;

class AuthService {
  public static setToken(token: string) {
    return localStorage.setItem("token", token);
  }

  public static getStoredToken() {
    return localStorage.getItem("token");
  }

  public static async Login(email: string, password: string) {
    try {
      const req = await fetch(`${process.env.REACT_APP_API_URL}${LOGIN}`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          password,
        }),
      });
      const data = await req.json();
      return data;
    } catch (err) {
      console.log(err);
    }
  }

  public static async Register(
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    role: string,
  ) {
    const req = await fetch(`${process.env.REACT_APP_API_URL}${REGISTER}`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        firstName,
        lastName,
        email,
        password,
        role,
      }),
    });

    const data = await req.json();
    return data;
  }
  catch(err: Error) {
    console.error(err);
  }
}

export default AuthService;
