import React, { lazy } from "react";
import { HamburgerMenuProvider } from "./context/HamburgerMenuContext";
import { TokenProvider } from "./context/TokenContext";
import { WorkerProvider } from "./context/WorkerContext";
import { tests } from "./utils/tests";

const AppRoutes = lazy(() => import("./components/Routes/Routes"));

tests();

const App = () => {
  return (
    <div>
      <TokenProvider>
        <WorkerProvider>
          <HamburgerMenuProvider>
            <AppRoutes />
          </HamburgerMenuProvider>
        </WorkerProvider>
      </TokenProvider>
    </div>
  );
};

export default App;
